import { Component } from "@angular/core";
import { AlertController, NavController, Platform } from "@ionic/angular";
import { StatusBar } from "@ionic-native/status-bar/ngx";
import { Location } from "@angular/common";
import { Plugins } from "@capacitor/core";

const { App } = Plugins;
@Component({
  selector: "app-root",
  templateUrl: "app.component.html",
  styleUrls: ["app.component.scss"],
})
export class AppComponent {
  navigate: any;
  public selectedPath = "";
  public darkMode: boolean;
  public alertShown: boolean = false;
  public alert;

  constructor(
    private platform: Platform,
    private statusBar: StatusBar,
    private location: Location,
    private navCtrl: NavController,
    private alertCtrl: AlertController
  ) {
    this.sideMenu();
    this.initializeApp();
  }

  navigateToSurvey() {
    window.open(
      "https://docs.google.com/forms/d/e/1FAIpQLSf8RBWIqfGyAwdYwrZDzkIc_YIA9HLAHVRNfIt18NhPrDNjFw/viewform"
    );
  }

  initializeApp() {
    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
    });

    this.platform.backButton.subscribeWithPriority(
      999,
      (processNextHandler) => {
        if (
          (this.location.isCurrentPathEqualTo("/app/home") ||
            this.location.isCurrentPathEqualTo("/app/ingredient-search") ||
            this.location.isCurrentPathEqualTo("/app/list") ||
            this.location.isCurrentPathEqualTo("/app/profile") ||
            this.location.isCurrentPathEqualTo("/login")) &&
          !this.alertShown
        ) {
          this.presentExitAlert();
          this.alertShown = true;
        } else if (
          (this.location.isCurrentPathEqualTo("/app/home") ||
            this.location.isCurrentPathEqualTo("/app/ingredient-search") ||
            this.location.isCurrentPathEqualTo("/app/list") ||
            this.location.isCurrentPathEqualTo("/app/profile") ||
            this.location.isCurrentPathEqualTo("/login")) &&
          this.alertShown
        ) {
          this.alert.dismiss();
          this.alertShown = false;
        } else {
          this.navCtrl.back();
        }
      }
    );
  }

  sideMenu() {
    this.navigate = [
      {
        title: "Home",
        url: "/app/home",
        icon: "home",
      },
      {
        title: "Zutatenkochen",
        src: "../../assets/icon/cooking-pot.svg",
        url: "/app/ingredient-search",
        type: "custom",
      },
      {
        title: "Rezept erstellen",
        url: "edit-recipe",
        icon: "add-circle",
      },
      {
        title: "Einkaufsliste",
        url: "/app/list",
        src: "../../../assets/icon/shoppinglist.svg",
        type: "custom",
      },
      {
        title: "Profil",
        url: "/app/profile",
        icon: "person",
      },
    ];
  }

  // Presents alert
  public async presentExitAlert() {
    this.alertShown = true;
    this.alert = await this.alertCtrl.create({
      message: "Möchtest du die App verlassen?",
      buttons: [
        {
          text: "Abbrechen",
          role: "cancel",
          handler: () => {
            this.alert.dismiss();
            this.alertShown = false;
          },
        },
        {
          text: "Bestätigen",
          handler: () => {
            App.exitApp();
          },
        },
      ],
    });
    await this.alert.present();
  }
}
