import { NgModule } from "@angular/core";
import { PreloadAllModules, RouterModule, Routes } from "@angular/router";
import { AuthGuard } from "./guards/auth.guard";
import { CreateRecipeGuard } from "./guards/create-recipe.guard";

const routes: Routes = [
	{
		path: "",
		redirectTo: "login",
		pathMatch: "full",
	},
	{
		path: "home",
		redirectTo: "app/home",
		pathMatch: "full",
	},
	{
		path: "search",
		redirectTo: "app/search",
		pathMatch: "full",
	},
	{
		path: "edit-recipe",
		redirectTo: "edit-recipe",
		pathMatch: "full",
	},
	{
		path: "list",
		redirectTo: "app/list",
		pathMatch: "full",
	},
	{
		path: "profile",
		redirectTo: "app/profile",
		pathMatch: "full",
	},
	{
		path: "editprofile",
		redirectTo: "app/profile/editprofile",
		pathMatch: "full",
	},
	{
		path: "login",
		loadChildren: () => import("./pages/login/login.module").then((m) => m.LoginPageModule),
	},
	{
		path: "register",
		loadChildren: () => import("./pages/register/register.module").then((m) => m.RegisterPageModule),
	},
	{
		path: "app",
		loadChildren: () => import("./pages/app/app.module").then((m) => m.AppPageModule),
	},
	{
		path: "**",
		redirectTo: "login",
		pathMatch: "full",
	},
];

@NgModule({
	imports: [RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })],
	exports: [RouterModule],
})
export class AppRoutingModule {}
